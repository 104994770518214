
input:disabled {
    background-color: rgba(0, 0, 0, 0.08);
    color: #000;
    opacity: 0.4;
}

input, select { font-size: 100%; }

form label {
    font-size: 0.857em;
    color: #6B778C;
    font-weight: 600;
}

div[class*='Page__Navigation-'] {
}

